html, body {
  margin: 0;
}

.box {
  z-index: -1;
  background: radial-gradient(at bottom, #4a0000, #000) 0 0 / cover no-repeat;
  flex-direction: column;
  align-items: center;
  min-height: 100dvh;
  display: flex;
}

.wrap img {
  object-fit: contain;
  flex: 1;
  width: 100%;
  max-width: 500px;
  height: auto;
  display: flex;
}

.fonts {
  text-align: center;
  object-fit: contain;
  flex: 1;
  padding-bottom: 20px;
  display: flex;
}

a.fa-x-twitter {
  text-decoration: none;
}

a.fa-x-twitter:hover {
  transition: all .5s;
  color: #fff !important;
}

a.fa-telegram {
  text-decoration: none;
}

a.fa-telegram:hover {
  transition: all .5s;
  color: #fff !important;
}

a.fa-fire {
  text-decoration: none;
}

a.fa-fire:hover {
  transition: all .5s;
  color: #fff !important;
}

.glowing-circle {
  animation: 1.5s ease-in-out infinite alternate glowing;
}

@keyframes glowing {
  from {
    filter: drop-shadow(0 0 2px #fff);
  }

  to {
    filter: drop-shadow(0 0 10px #fff);
  }
}

.vid {
  z-index: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  position: fixed;
  inset: 0;
}

canvas {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.fa-gradient {
  color: #0000;
}

.fa-gradient:before {
  background-image: linear-gradient(225deg, #423e3e 1%, #928dab);
  -webkit-background-clip: text;
  background-clip: text;
  animation: 2s infinite shine;
  -webkit-mask-image: linear-gradient(-75deg, #0009 30%, #000 50%, #0009 70%);
  mask-image: linear-gradient(-75deg, #0009 30%, #000 50%, #0009 70%);
  -webkit-mask-size: 200%;
  mask-size: 200%;
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
    mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
    mask-position: -50%;
  }
}

.wrap {
  flex: 1;
  display: flex;
  position: relative;
}

.left_eye {
  float: left;
  color: #0000;
  margin: 0;
  padding: 0;
  font-size: .5em;
  position: absolute;
  top: 47.9%;
  left: 43.5%;
}

.right_eye {
  float: right;
  color: #0000;
  margin: 0;
  padding: 0;
  font-size: .5em;
  position: absolute;
  top: 47.9%;
  right: 42.3%;
}

.glowing-eye {
  animation: 20s infinite alternate glowinge;
}

@keyframes glowinge {
  0% {
    text-shadow: 0 0 1em #00f;
  }

  10% {
    text-shadow: 0 0 1em red;
  }

  20% {
    text-shadow: 0 0 1em green;
  }

  30% {
    text-shadow: 0 0 1em #ff0;
  }

  40% {
    text-shadow: 0 0 1em purple;
  }

  50% {
    text-shadow: 0 0 1em #fff;
  }

  60% {
    text-shadow: 0 0 1em pink;
  }

  70% {
    text-shadow: 0 0 1em #7fffd4;
  }

  80% {
    text-shadow: 0 0 1em gold;
  }

  90% {
    text-shadow: 0 0 1em indigo;
  }

  1000% {
    text-shadow: 0 0 1em #0f0;
  }
}
/*# sourceMappingURL=index.782b32b4.css.map */
