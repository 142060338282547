html {
  margin: 0;
}
body {
  margin: 0;

  /*background: radial-gradient(#4a0000, #000 100%);*/
  /*background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 20%);*/

  /*min-height: 100vh; /*Fall back para aquellos que no aceptan dvh*/
  /*min-height: 100dvh;*/
}

.box {
  display: flex;
  align-items: center;
  /*justify-content: center; /* not really needed with auto margin on img*/
  flex-direction: column;
  min-height: 100dvh;

  background: radial-gradient(at 50% bottom, #4a0000, #000);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.wrap img {
  width: 100%;
  height: auto;
  max-width: 500px; /*actual image width*/
  display: flex; /* new; nested flex container */
  flex: 1;
  object-fit: contain;
}

.fonts {
  text-align: center;
  display: flex; /* new; nested flex container */
  flex: 1;
  object-fit: contain;
  padding-bottom: 20px;
}

a.fa-x-twitter {
  text-decoration: none;
  /*height: 80px;*/
}
a.fa-x-twitter:hover {
  color: #fff !important;
  transition: 500ms;
}

a.fa-telegram {
  text-decoration: none;
  /*height: 80px;*/
  /*padding-top: 20px;*/
}
a.fa-telegram:hover {
  color: #fff !important;
  transition: 500ms;
}

a.fa-fire {
  text-decoration: none;
  /*padding-top: 20px;*/
  /*height: 80px;*/
}
a.fa-fire:hover {
  color: #fff !important;
  transition: 500ms;
}

.glowing-circle {
  animation: glowing 1.5s ease-in-out infinite alternate;
}
@keyframes glowing {
  from {
    filter: drop-shadow(0px 0px 2px #fff);
  }
  to {
    filter: drop-shadow(0px 0px 10px #fff);
  }
}
.vid {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  z-index: 0;
}
canvas {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.fa-gradient {
  color: transparent;
}
.fa-gradient:before {
  background-image: linear-gradient(225deg, #423e3e 1%, #928dab);
  background-clip: text;
  mask-image: linear-gradient(
    -75deg,
    rgba(0, 0, 0, 0.6) 30%,
    #000 50%,
    rgba(0, 0, 0, 0.6) 70%
  );
  mask-size: 200%;
  animation: shine 2s infinite;
}
@keyframes shine {
  from {
    mask-position: 150%;
  }

  to {
    mask-position: -50%;
  }
}

.wrap {
  position: relative;
  display: flex; /* new; nested flex container */
  flex: 1;
}
.left_eye {
  position: absolute;
  top: 47.9%;
  left: 43.5%;
  padding: 0;
  margin: 0;
  font-size: 0.5em;
  float: left;
  color: transparent;
}
.right_eye {
  position: absolute;
  top: 47.9%;
  right: 42.3%;
  padding: 0;
  margin: 0;
  font-size: 0.5em;
  float: right;
  color: transparent;
}
.glowing-eye {
  animation: glowinge 20s infinite alternate;
}
@keyframes glowinge {
  0% {
    text-shadow: 0 0 1em blue;
  }
  10% {
    text-shadow: 0 0 1em red;
  }
  20% {
    text-shadow: 0 0 1em green;
  }
  30% {
    text-shadow: 0 0 1em yellow;
  }
  40% {
    text-shadow: 0 0 1em purple;
  }
  50% {
    text-shadow: 0 0 1em white;
  }
  60% {
    text-shadow: 0 0 1em pink;
  }
  70% {
    text-shadow: 0 0 1em aquamarine;
  }
  80% {
    text-shadow: 0 0 1em gold;
  }
  90% {
    text-shadow: 0 0 1em indigo;
  }
  1000% {
    text-shadow: 0 0 1em lime;
  }
}
